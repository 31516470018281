/**
 * User store interface
 */
interface UserState {
  login: string;
  referralKey: string;
  userImg: string;
  settings: {
    isPushOn: boolean;
  };
  addresses: ModelUserAddressesResponse[];
}

/**
 * Хранилище данных пользователя
 */
export const useUserStore = defineStore('userStore', () => {
  const state = ref<UserState>({
    login: '',
    referralKey: '',
    userImg: '',
    settings: {
      isPushOn: false,
    },
    addresses: [],
  });

  function resetState() {
    state.value.login = '';
    state.value.referralKey = '';
    state.value.userImg = '';
    state.value.settings.isPushOn = false;
    state.value.addresses = [];
  }

  function logout() {
    resetState();
  }

  const {
    data: userData,
    error: userError,
    execute: fetchUserData,
  } = useApiUserGetInfo({
    immediate: false,
  });

  function setUser(user?: ModelUserInfoResponse) {
    // TODO: Check this functionality
    if (!user) {
      resetState();
      return;
    }

    state.value.login = user.login;
    state.value.referralKey = user.referralKey;
    state.value.userImg = user.userImg;
    state.value.settings.isPushOn = user.settings.isPushOn;
  }

  async function fetchUser() {
    await fetchUserData();
    const data = userData.value;

    if (!data) {
      console.error('Error user data fetch', userError.value);
      return;
    }

    setUser(data);
  }

  const {
    data: userAddressesData,
    error: addressError,
    execute: fetchUserAddressesData,
  } = useApiUserGetAddresses({ immediate: false });

  function setUserAddresses(addresses?: ModelUserAddressesResponse[]) {
    if (!addresses) {
      state.value.addresses = []; // Сбрасываем массив адресов, если нет данных
      return;
    }

    state.value.addresses = addresses; // Устанавливаем массив объектов
  }

  /**
   * Получить адреса пользователя
   */
  async function fetchUserAddresses() {
    await fetchUserAddressesData();
    const data = userAddressesData.value;

    if (!data) {
      console.error('Error fetching user addresses', addressError.value);
      return;
    }

    setUserAddresses(data);
  }

  return {
    logout,
    fetchUser,
    fetchUserAddresses,
    isLoggedIn: computed(() => state.value.login !== ''),
    user: computed(() => ({
      image: state.value.userImg,
      login: state.value.login,
    })),
    addresses: computed(() => state.value.addresses),
  };
});
