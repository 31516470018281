import { useUserEndpoints } from '../routes/users.routes';

/**
 * Получить данные текущего пользователя
 */
export function useApiUserGetInfo<T = ModelUserInfoResponse>(options?: UseApiOptions<T>) {
  return useApi<T>(useUserEndpoints().me(), {
    ...options,
    model: modelUserInfoResponse,
  });
}

/**
 * Получить данные с адресами текущего пользователя
 */
export function useApiUserGetAddresses<T = ModelUserAddressesResponse>(options?: UseApiOptions<T>) {
  return useApi<T>(useUserEndpoints().addresses(), {
    ...options,
    model: modelUserAddressesResponse,
  });
}
