// импорты для тестов
import { API_DEFAULT_VERSION } from '../api.const';
import { versioned } from '~/utils/api.utils';

/**
 * Маршруты для запросов данных пользователя
 */
export const useUserEndpoints = (version?: number) => {
  const withVersion = version && Number.isInteger(version) ? version : API_DEFAULT_VERSION;

  return {
    /**
     * Информация о себе
     */
    me: () => versioned('/user/me', withVersion),

    /**
     * Информация о своих рефералах
     */
    referrals: () => versioned('/user/referrals', withVersion),

    /**
     * Адреса пользователя для разных сетей
     */
    addresses: () => versioned('/user/addresses', withVersion),
  };
};
