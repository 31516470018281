import { z } from 'zod';
import { AVAILABLE_NETWORKS } from './coins.model';

const userSettings = z.object({
  /**
   * Включены ли пуши
   */
  isPushOn: z.boolean(),
});

export const modelUserInfoResponse = z.object({
  /**
   * Логин пользователя
   */
  login: z.string(),
  /**
   * Аватар пользователя
   */
  userImg: z.string(),
  /**
   * Реферральный код пользователя
   */
  referralKey: z.string(),
  /**
   * Настройки пользователя
   */
  settings: userSettings,
});

/**
 * Модель с информацией о пользователе
 */
export type ModelUserInfoResponse = z.infer<typeof modelUserInfoResponse>;

/**
 * Модель адреса пользователя в сети
 */
export const modelUserAddress = z.object({
  /**
   * Сеть
   */
  network: z.enum(AVAILABLE_NETWORKS),
  /**
   * Адрес кошелька
   * TODO: добавить валидацию адресов
   */
  address: z.string(),
});

/**
 * Модель всех адресов пользователя
 */
export const modelUserAddressesResponse = z.array(modelUserAddress);

/**
 * Тип с информацией обо всех адресах пользователя в сетях
 */
export type ModelUserAddressesResponse = z.infer<typeof modelUserAddressesResponse>;
